import React, { useState, useContext, useEffect } from "react";
import {
  Layout,
  Grid,
  Avatar,
  Typography,
  Input,
  Tooltip,
  Button,
  Drawer,
} from "antd";
import {
  IoChatboxOutline,
  IoHeartOutline,
  IoBagHandleOutline,
  IoCartOutline,
  IoHomeOutline,
  IoGitBranchSharp,
  IoBarChartSharp,
  IoCopyOutline,
  IoMenuOutline,
  IoBarChartOutline,
  IoBagCheckOutline,
  IoPeopleOutline,
  IoReorderFourOutline,
} from "react-icons/io5";
import SideMenu from "./SideMenu";
import { Link, useLocation } from "react-router-dom";
const { Header } = Layout;
const { Text } = Typography;

export default function Bottombar() {
  //   const profile = localStorage.getItem("profile");
  //   let profileData = profile ? JSON.parse(profile) : null;
  //   const location = useLocation();
  const [drawer, setDrawer] = useState(false);

  const location = useLocation();

  useEffect(() => {
    setDrawer(false);
  }, [location]);

  return (
    <>
      {
        <div className="bottomBar">
          <Link
            to={"/"}
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <IoHomeOutline size={18} color={"#000"} />
              <Text style={{ fontSize: "12px", marginTop: 4 }}>Dashboard</Text>
            </div>
          </Link>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              flex: 1,
            }}
            onClick={() => setDrawer(true)}
          >
            <IoReorderFourOutline size={18} color={"#000"} />
            <Text style={{ fontSize: "12px", marginTop: 4 }}>Menu</Text>
          </div>

          <Link
            passHref
            to={"/orders"}
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <IoCopyOutline size={18} color={"#000"} />
              <Text style={{ fontSize: "12px", marginTop: 4 }}>Orders</Text>
            </div>
          </Link>

          <Link
            to={"/profile"}
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <IoPeopleOutline size={18} color={"#000"} />
              <Text style={{ fontSize: "12px", marginTop: 4 }}>Account</Text>
            </div>
          </Link>
        </div>
      }
      <Drawer
        placement="left"
        headerStyle={{ padding: 0 }}
        width={"70%"}
        bodyStyle={{ padding: "8px" }}
        closeIcon={false}
        onClose={() => setDrawer(false)}
        open={drawer}
      >
        <SideMenu setDrawer={setDrawer} />
      </Drawer>
    </>
  );
}
